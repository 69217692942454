import type {
	CmLinkable,
	CmSelectionRulesImpl,
	CmTeasable,
	PageGridPlacement,
} from '~/graphql/generated/coremedia/type-document-node'
import type { InGridItem, PageContentData } from '~/lib/types/coremedia.interface'
import type { ClientProductData } from '~/lib/types/product.interface'
import { ensureString, ensureNonNullishArray } from '~/types/strict-null-helpers'
import { getModuleSetting } from './cms/legacy/helper'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import type { PlacementSelectionRulesContext } from './client-server/content'
import { NUMBER_PRODUCTS_PER_PAGE } from '~/lib/products'

export function applyPlacementSelectionRules(items: CmLinkable[], customer: UserWithSession | null) {
	const getContentByRule = (rules, rule) => rules.filter((item) => item.rule === rule).map((item) => item?.target)
	let rtn: CmLinkable[] = []

	items.forEach((item) => {
		if (item?.type === 'CMSelectionRules') {
			// Personalization item
			const pItem = item as CmSelectionRulesImpl

			// If available, return loyalty content
			if (customer?.isLoyalty) {
				const loyaltyCustomerItems = getContentByRule(pItem.rules, 'referrer.url="loyalty"')
				if (loyaltyCustomerItems) {
					rtn = [...rtn, ...loyaltyCustomerItems]
					return
				}
			}

			// Otherwise, return Default content
			rtn = [...rtn, ...pItem.defaultContent]
			return
		}

		// Pass thru everything else
		rtn.push(item)
	})

	return rtn
}

/**
 * Type guard to determine if the given object is a content item or product data
 * @param item
 * @returns
 */
export function isProductData(item: ClientProductData | CmTeasable): item is ClientProductData {
	// Check to hide products on PLP that do not have any assets in the colors array.
	// TODO: Remove when EPMD-10300 is completed.
	return !!(item as ClientProductData).style && !!(item as ClientProductData).colors?.some((color) => color.assets)
}

/**
 * Type guard to determine if the given object is a content item or product data
 * @param item
 * @returns
 */
export function isContentModule(item: ClientProductData | CmTeasable): item is CmTeasable {
	return (item as CmTeasable).type?.startsWith('CM')
}

/**
 * Determines the number of Grid columns to occupy based on the given content
 * and whether we are viewing this within a mobile breakpoint
 * @param content
 * @param isMobile
 * @returns
 */
export function computeGridBreakpointsFromContent(content: CmTeasable) {
	return {
		xs: computeColumnCountFromContent(content, true),
		md: computeColumnCountFromContent(content, false),
	}
}

/**
 * This associate the different configuration values (mb-sig, dt-sig, etc.) to
 * column counts and configurations.
 */
const InGridColumnCountMap = [
	{
		name: 'mb-sig',
		count: 1,
		mobile: true,
	},
	{
		name: 'mb-dig',
		count: 2,
		mobile: true,
	},
	{
		name: 'mb-tig',
		count: 2,
		mobile: true,
	},
	{
		name: 'dt-sig',
		count: 1,
		mobile: false,
	},
	{
		name: 'dt-dig',
		count: 2,
		mobile: false,
	},
	{
		name: 'dt-tig',
		count: 3,
		mobile: false,
	},
]

/**
 * Returns the total number of "slots" that will be occupied by the given
 * content on desktop or mobile.  We calculate this based on the viewtype
 * of the content.
 * @param content
 * @param isMobile
 * @returns
 */
export function computeColumnCountFromContent(content: CmTeasable, isMobile: boolean) {
	// Get mobile settings from content file
	const mobileSettingsFromContent = content?.uaMobileLayoutSettings?.inGridSetting
	// Check for settings in the `module-settings-map` file
	const moduleSettings = getModuleSetting(ensureString(content.viewtype), 'inGridSetting')?.split(' ')

	if (moduleSettings) {
		// If there are mobile settings in the content, look at them to grab that data first if not, use
		// module settings as the default. This adds support to the user selecting a different module with
		// in CoreMedia.
		const settings = mobileSettingsFromContent && isMobile ? mobileSettingsFromContent : moduleSettings

		// This will pull the settings from the module settings which includes both the desktop and mobile
		//	column span in the form of a formatted string (e.g. dt-sig == desktop single in grid).  We use
		//	to map it to the right column count based on the map found above.  For example, if inGridSetting
		//	is set to "dt-dig mb-sig" then this will return 1 if isMobile is set to true and 2 if false.
		return (
			InGridColumnCountMap.filter((ig) => settings.includes(ig.name) && ig.mobile === isMobile)?.map(
				(ig) => ig.count,
			)?.[0] || 1
		)
	}

	// DEFAULT TO RETURN 1 IF SETTING NOT FOUND
	return 1
}

export type ProductListSlot = ClientProductData | CmLinkable | undefined

/**
 * Builds a grid with content by integrating product data and content modules into a specified grid structure.
 * This function handles both mobile and desktop layouts, paginated offsets, and overflow caused by content spanning multiple grid columns.
 *
 * @param {PageContentData | undefined} contentData - Data containing page content, including grid placements and settings.
 * @param {ClientProductData[]} productData - List of product data to display in the grid.
 * @param {boolean} isMobile - Determines if the view is mobile or desktop for adjusting grid layout.
 * @param {number} offset - Starting index for paginated grid content.
 * @param {number} count - Number of items to display in the current paginated view.
 *
 * @returns {{topContent: any[], bottomContent: any[], gridItems: ProductListSlot[]}} -
 * An object containing:
 *   - `topContent`: Array of content for the top of the grid.
 *   - `bottomContent`: Array of content for the bottom of the grid.
 *   - `gridItems`: Array of product data interwoven with content modules in grid structure.
 */
export const buildGridWithContent = (
	contentData: PageContentData | undefined,
	productData: ClientProductData[],
	isMobile: boolean,
	offset: number,
	count: number,
) => {
	if (!contentData) {
		return {
			topContent: [],
			bottomContent: [],
			gridItems: productData,
		}
	}

	// This filters through all the page placements and just gets the `ingrid-content`
	const gridContent = contentData?.placements?.filter((placement) => placement.name === 'ingrid-content') || []
	const bottomContent = contentData?.placements?.filter((placement) => placement.name === 'category-grid-bottom') || []
	const gridPlacementData = contentData?.settings?.inGridItems
	const hasGridCoordinates = !!contentData?.gridCoordinates?.length
	const gridItems: ProductListSlot[] = [...productData]

	// Overflow track the number of slots that are occupied by double and triple ingrids
	//	or in cases where single ingrids are occupying two slots ( this happens when the
	//	user is viewing on mobile ).  It is the total number of *extra* slots occupied
	//	by the items.  For example if you have a grid of four items and one of those items
	//	is a double in grid then the total overflow would be "1" because there is one
	//	item that takes up two spaces.  So that would be one "extra" slot occupied.
	let overflow = 0

	// DATE: Sept 2024
	/* NOTE: gridCoordinates is meant to be a replacement for settings.inGridSettings which uses a different data structure. The update was meant to both enhance the user experience within CoreMedia for the content creators, as well as to provide a more straight forward way to position marketing items within the PDP grids. Eventually the settings.inGridItems data will be phased out, but for now we have a mixture of both. Good news is, from CoreMedia only one method can be used per page, so if settings.InGridItems has data, then gridCoordinates will be null and vice versa.
	
	Updates that will affect the current code: 
	- SB-7052: https://underarmour.atlassian.net/browse/SB-7052 <- Addresses ragged bottom issue and might adjust the offset calculation. 
	- There's a potential CM update where gridCoordinates will gain another data field called `page`. Right now if something is on the second page or higher, the `row` will keep counting upwards (i.e. there should more or less be 8 rows on a page for desktop and 12 rows on a page for mobile non inclusive of content (24 products / 3 or 2 rows). This current way requires a bit of abstract calculation because we're starting with the same product array size for each page. 
	*/

	if (hasGridCoordinates) {
		const columnCount = isMobile ? 2 : 3
		contentData?.gridCoordinates?.forEach((cord, index) => {
			const { row, colspan, alignment } = cord[isMobile ? 'mobile' : 'desktop']
			const contentModuleToInsertIntoGrid = gridContent?.[0].uaFilteredItems[index]
			const absoluteIndexToInsertContent = row * columnCount + alignment - overflow

			// We get all of the content regardless of page to be placed at once. Until we have the `page` parameter from the CoreMedia data, we need to determine if something is meant to be rendered on the current or on a subsquent page. To do this we figure out if the position we're meant to insert content in, is within the paginated start and end product amounts (i.e. page 1 has an offset of 0 and page 2 has an offset of 24)
			const productRangeUpperBound = offset + NUMBER_PRODUCTS_PER_PAGE
			const isOnPage = absoluteIndexToInsertContent <= productRangeUpperBound && absoluteIndexToInsertContent >= offset

			// This last calculation allows us handle what the actual index we need to insert the content at. If something has an absoluteIndex of 50 and offset moves in increments of 24, when the offset is 48 (page 3), the actual index that we insert the content at will be 2.
			const gridPositionBasedOnPage =
				offset === 0 ? absoluteIndexToInsertContent : absoluteIndexToInsertContent % offset

			if (isOnPage) gridItems.splice(gridPositionBasedOnPage, 0, contentModuleToInsertIntoGrid)

			overflow += colspan - 1
		})
	}

	// Now interweave the In-Grids at the indices specified.
	if (gridPlacementData && !hasGridCoordinates) {
		// NOTE: This calculates the position on the grid as intended as opposed to SFCC which
		//	places the content in the wrong grid spot in some cases. The result is that this
		//	implementation will have in-grids appear in different places with the same settings
		//	provided.  For that reason, we will need to notify the content team that until we are
		//	at 100%, the in-grids will appear at different places on SFCC vs. SB.
		const getZeroBasedIndex = (ig: InGridItem) => Math.max((isMobile ? ig.mobilePosition : ig.desktopPosition) - 1, 0)

		gridPlacementData
			.map((ig, index) => {
				// Gets the index as it would appear in a zero-based grid where the top left
				//	is at index 0
				const zeroBasedPosition = getZeroBasedIndex(ig)

				// make sure that the offset specific is actually within the range that is
				//	currently being displayed
				if (offset <= zeroBasedPosition && zeroBasedPosition <= offset + count) {
					// See if we actually have something to show at that index
					return {
						content: gridContent?.[0].uaFilteredItems?.[index],
						zeroBasedPosition,
					}
				}

				// If we get here then the index falls outside of the page boundaries.  In other words,
				//	if the specified index is 16 but we are only showing items 1-12 then we don't
				//	show this.
				return {
					content: null,
					zeroBasedPosition,
				}
			})
			.forEach((item, index) => {
				if (item.content) {
					gridItems.splice(item.zeroBasedPosition - overflow - offset, 0, gridContent?.[0].uaFilteredItems?.[index])

					// The overflow here is the number of *extra* slots taken up by the content.  For example,
					//	a double in-grid will occupy two slots so the overflow for it will be 1 (2-1)
					overflow += computeColumnCountFromContent(gridContent?.[0].uaFilteredItems?.[index], isMobile) - 1
				}
			})
	}

	return {
		topContent: [],
		bottomContent,
		gridItems: ensureNonNullishArray(gridItems),
	}
}

/**
 * Given a placement, extract the list of items from the placement. This is a helper function to get the items from the placement
 * because UnderArmour uses a custom items collections and this has changed over time.  CoreMedia uses the `items` property, but
 * UA introduced `uaMergedItems` originally and then eventually introduced `uaFilteredItems`.
 *
 * @param placement
 * @returns
 */
export function getListOfItemsInPlacement(placement: PageGridPlacement) {
	const { items, uaFilteredItems, uaMergedItems } = placement // get all 3 possible placements types.

	// uaFilteredItems takes prioirty in return because it is more specific, only applying to the PLPs
	if (uaFilteredItems) return uaFilteredItems

	// uaMergedItems is the "UA Default" placements that handles some additional functions
	if (uaMergedItems) return uaMergedItems

	// `items` is the CoreMedia default but we typically do not use this at UA
	return items
}

/**
 * This function will return the list of items in a placement based on the selection rules.  If there are no selection rules, it will
 * return the list of items in the placement.  If there are selection rules, it will apply the rules and return the list of items that
 * match the rules.  Selection rules are a feature in CoreMedia that allows for personalization of content based on various filtering
 * rules like customer type.  We used this to show different content to loyalty customers vs non-loyalty customers.  But
 * it can be used for a number of different use-cases.
 * @param placement
 * @param context
 * @returns
 */
export function getListOfItemsInPlacementBasedOnSelectionRules(
	placement: PageGridPlacement,
	context: PlacementSelectionRulesContext,
) {
	const items = getListOfItemsInPlacement(placement)
	const { customer } = context
	return customer ? applyPlacementSelectionRules(items, customer) : items
}

export function getCategoryBanner(pageContent: PageContentData) {
	const categoryBanner = pageContent?.placements.filter((placement) => placement.name === 'category-banner')
	const categoryBannerHero = categoryBanner?.[0].uaFilteredItems[0] || null
	return categoryBannerHero
}

export function getGuidedShoppingModule(pageContent: PageContentData) {
	const guidedShoppingPlacement = pageContent?.placements?.filter((placement) => placement.name === 'guided-shopping')
	return guidedShoppingPlacement?.[0]?.uaFilteredItems?.[0] || null
}
